import _ from 'lodash'

export function checkDiagonalWin(selected_tiles) {
  let diagonal_arr = [0, 6, 12, 18, 24]
  let current_arr = selected_tiles
  return _.difference(diagonal_arr, current_arr).length === 0
}

export function checkInverseDiagonalWin(selected_tiles){
  let inverse_diagonal_arr = [4, 8, 12, 16, 20]
  let current_arr = selected_tiles
  return _.difference(inverse_diagonal_arr, current_arr).length === 0
}

export function checkVerticalWin(selected_tiles) {
  let win = false
  let top_row = _.range(0, 5)
  _.forEach(top_row, (n) => {
    let vertical_array =  _.reduce(top_row, (acc, m) => {
      acc.push(n+5*m);
      return acc
    }, []);
    if (_.difference(vertical_array, selected_tiles).length === 0) {
      win = true;
    }
  });
  return win
}
export function checkHorizontalWin(selected_tiles) {
  let win = false
  let left_column = _.range(0, 25, 5)
  _.forEach(left_column, (n) => {
    let horizontal_array =  _.reduce(_.range(0,5), (acc, m) => {
      acc.push(n+m);
      return acc
    }, []);
    if (_.difference(horizontal_array, selected_tiles).length === 0) {
      win = true;
    }
  });
  return win
}
