import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import axios from 'axios';

import Card from 'components/card'
import Title from 'components/title'

import 'styles/card.scss'
import 'styles/bingo.scss'
import 'styles/tile.scss'

const HEADER = ["B", "I", "N", "G", "O"]

class Bingo extends Component {
    constructor(props) {
    super(props);
      this.state = {
        card: props.card,
        tiles: props.tiles
      }
  }

  componentDidMount() {
    this.setAjaxToken();
  }

  setAjaxToken() {
    let node = document.head.querySelector("[name=csrf-token]");
    if (node) {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = node.content;
    }
  }

  deleteCard() {
    axios.delete(`/bingo_cards/${this.props.card.id}`, {
    }).then(() => {
      window.location.href = '/bingo_cards';
    })

  }

  printCard() {
    const input = document.getElementById('js-print')
    const card = this.props.card
    const user = this.props.user
    html2canvas(input, {scale: 1}).then((canvas) => {
      const card_image = canvas.toDataURL('image/png')
      const pdf = new jsPDF();
      pdf.addImage(card_image, 'PNG', 0, 0);
      pdf.save(`${user}: ${card.title}.pdf`);
    })
  }

  render () {
    return (
      <div className="b-bingo">
        <Title user={this.props.user} title={this.props.card.title} id={this.props.id}/>
        <Card
          card = {this.state.card}
          tiles = {this.state.tiles}
          selectedTiles={this.props.selectedTiles}
          title = {this.props.title}
          isComplete = {this.props.card.complete}
          hasBingo= {this.props.card.bingo}
        />
        <div>
          <button className="btn btn-primary b-bingo__button" onClick={ () => this.printCard() }>Download Card as PDF</button>
          <button className="btn btn-primary b-bingo__button" onClick={ () => this.deleteCard() }>Delete Card</button>
        </div>
      </div>
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('bingo');
  if (!node) {
    return;
  }
  let card = JSON.parse(node.dataset.card)
  let tiles = JSON.parse(node.dataset.tiles)
  let user = JSON.parse(node.dataset.user)
  let selected_tiles = JSON.parse(node.dataset.selectedTiles)
  let id = JSON.parse(node.dataset.id)

  ReactDOM.render(
    <Bingo card={card} tiles={tiles} title={HEADER} selectedTiles={selected_tiles} user={user} id={id}/>, node
  )
});


export default Bingo
