import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import axios from 'axios';

import css from 'styles/tile.scss'

class Tile extends Component {
    constructor(props) {
    super(props);
      this.state = {
        value: this.props.value,
        isSelected: this.props.isSelected
      }

      this.handleClick = this.handleClick.bind(this);
  }

  handleClick(position) {
     new Promise((resolve) => {
       this.setState({isSelected: !this.state.isSelected})
       resolve();
     }).then(() => {
       let params = {
         tile: {
           is_selected: this.state.isSelected,
           id: this.props.tileId
         }
       };
       axios.put(`${this.props.endPointURL}/${this.props.cardId}/tiles/${this.props.tileId}`, params, {
         headers: { 'Accept': 'application/json' }
       }).then(() => {
         this.props.onTileClick(position)
       });
     });
    };

  render () {
    return (
      <div className={this.state.isSelected ? "b-tile b-tile--selected" : "b-tile"} onClick={()=>this.handleClick(this.props.position)}>
        <p>{this.state.value}</p>
      </div>
    )
  }
}

export default Tile
