import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'


const Title = (props) => {
  return (
    <div className="b-card__title>">
      <h2>{props.user}</h2>
      <h3>{props.title}</h3>
      <h4>Card Number: {props.id}</h4>
    </div>
  )
}

export default Title
