import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const style = {display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid black", backgroundColor: "#0C9FA5"}
const HeaderTile = (letter, key) => {
  return (
    <div className="header-tile>" style={style}><h1>{letter.letter}</h1></div>
  )
}

export default HeaderTile
