import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios';

import HeaderTile from 'components/header_tile'
import Tile from 'components/tile'

import {
  checkDiagonalWin,
  checkInverseDiagonalWin,
  checkHorizontalWin,
  checkVerticalWin
} from 'utils/check_bingo';

class Card extends Component {
    constructor(props) {
    super(props);
      this.state = {
        selected_tiles: props.selectedTiles,
        is_complete: props.card.complete,
        has_bingo: props.card.bingo
      }
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }


  onTileSelect(position) {
    let arr = this.state.selected_tiles
    if (_.includes(arr, position)) {
      _.remove(arr, (n) => {return n === position})
    } else {
      arr.push(position)
    }

    this.setState({selected_tiles: arr})
    if (this.isBingoBlackOut(this.state.selected_tiles)) {
      alert("Woohoo! Bingo Blackout!")
      this.updateBingoBlackoutStatus(true)
    } else if (this.isBingo(this.state.selected_tiles) && (this.state.has_bingo != true)) {
      alert("BINGO!") 
      this.updateBingoStatus(true)
    } else if (this.isBingo(this.state.selected_tiles)) {
      this.updateBingoStatus(true)
    } else {
      this.updateBingoStatus(false)
    }
    if (!this.isBingoBlackOut(this.state.selected_tiles)) {
      this.updateBingoBlackoutStatus(false)
    }
  }

  getSelectedTiles() {
    let arr = _.filter(this.state.tiles, (tile) => {
      return tile.is_selected
    });
    this.setState({ selected_tiles: arr })
  }

  isBingo(selected_tiles) {
     return (checkDiagonalWin(selected_tiles) || checkInverseDiagonalWin(selected_tiles) || checkVerticalWin(selected_tiles) || checkHorizontalWin(selected_tiles))
  }

  isBingoBlackOut(selected_tiles) {
    return _.values(selected_tiles).length === 25
  }

  updateBingoStatus(status) {
    this.setState({has_bingo: status})

    let params = {
      bingo: this.state.has_bingo
    }
    axios.put(`/bingo_cards/${this.props.card.id}`, params, {
      headers: { 'Accept': 'application/json' }
    })
  }

  updateBingoBlackoutStatus(status) {
    this.setState({is_complete: status})

    let params = {
      complete: this.state.is_complete
    }
    axios.put(`/bingo_cards/${this.props.card.id}`, params, {
      headers: { 'Accept': 'application/json' }
    })
  }

  render () {
    return (
      <div className="b-card__tiles-wrapper">
        <div className="b-card__header">
          {_.map(this.props.title, (letter) => <HeaderTile letter={letter} key={letter} />)}
        </div>
        {_.map(this.props.tiles, (tile) =>
          <Tile
            key={tile.id}
            value={tile.value}
            isSelected={tile.is_selected}
            cardId={this.props.card.id}
            gameId={this.props.card.bingo_game_id}
            tileId={tile.id}
            position={tile.position}
            endPointURL= '/bingo_cards'
            onTileClick = {this.onTileSelect.bind(this)}/>)}
      </div>
    )
  }
}

export default Card
